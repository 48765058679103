import React, { useEffect } from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { StylesWrapper } from './styles';
import { LexicalEditor } from 'lexical';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import nodesConfig from './nodesConfig';
import theme from './theme';
import OnChangePlugin from './plugins/OnChangePlugin';
import EditablePlugin from './plugins/EditablePlugin';
import { useSelector } from 'react-redux';
import { isEditMode as isEditModeSelector } from 'services/admin';
import ToolbarPlugin from './plugins/Toolbar';
import { getAdminThemeTypography, getThemeTypography } from 'services/themes';
import { isUserAdmin } from 'services/auth';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import TableCellActionMenuPlugin from './plugins/TablePlugin/TableActionMenuPlugin';
import TableCellResizer from './plugins/TablePlugin/TableCellResizer';
import { TableContext } from './plugins/TablePlugin';
import FloatingToolbarPlugin from './plugins/FloatingToolbarPlugin';
import InlineToolbar from './plugins/Toolbar/InlineToolbar';
import { DefaultEditorState, getDefaultParagraphNode } from './getDefaultEditorState';
import { useFontsContext } from 'contexts/FontsContext';
import SelectionProvider from './SelectionContext';

type RichTextEditorV3Props = {
  data: string | null;
  inline?: boolean;
  onChange: (data: string | null) => void;
  readOnly?: boolean;
  sticking: boolean;
};

const RichTextEditorV3: React.FC<RichTextEditorV3Props> = ({
  data,
  onChange,
  inline,
  sticking,
  readOnly,
  ...rest
}) => {
  const isEditMode = useSelector(isEditModeSelector);
  const isEditing = isEditMode && !readOnly;

  const [floatingAnchorElem, setFloatingAnchorElem] =
    React.useState<HTMLDivElement | null>(null);
  const onRef = React.useCallback((_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  }, []);

  const onError = React.useCallback((err: Error, _: LexicalEditor) => {
    // TODO: Log error to server ?
    // tslint:disable-next-line:no-console
    console.error(`Error in editor: ${err.message}`);
    // tslint:disable-next-line:no-console
    console.error(err.stack);
  }, []);

  const getInitialEditorState = React.useCallback((): string | null => {
    try {
      const parsed: DefaultEditorState = JSON.parse(data || '');
      // lexical editor can't have empty root node
      if (!parsed.root.children.length) {
        parsed.root.children.push(getDefaultParagraphNode([]));
        return JSON.stringify(parsed);
      }

      return JSON.stringify(parsed);
    } catch (err) {
      return data;
    }
  }, [data]);

  const renderToolbarPlugin = React.useCallback(() => {
    if (!isEditing) return null;

    if (!inline) {
      return <ToolbarPlugin />;
    }

    return (
      <FloatingToolbarPlugin>
        <InlineToolbar />
      </FloatingToolbarPlugin>
    );
  }, [isEditing, inline]);

  const fonts = useFilteredFonts();

  return (
    <SelectionProvider>
      <StylesWrapper fonts={fonts} isEditing={isEditing} inline={inline} {...rest}>
        <LexicalComposer
          initialConfig={{
            editable: false,
            editorState: getInitialEditorState(),
            nodes: nodesConfig,
            namespace: 'MyEditor',
            onError,
            theme,
          }}
        >
          <TableContext>
            <>
              <EditablePlugin isEditing={isEditing} />
              {renderToolbarPlugin()}
              {isEditing && !inline && <TableCellResizer />}
              {!inline && <TablePlugin />}
              {floatingAnchorElem && isEditing && !inline && (
                <>
                  <TableCellActionMenuPlugin
                    anchorElem={floatingAnchorElem}
                    sticking={sticking}
                  />
                </>
              )}
              <RichTextPlugin
                contentEditable={(
                  <div ref={onRef}>
                    <ContentEditable className="mce-content-body" />
                  </div>
                )}
                placeholder={null}
                ErrorBoundary={LexicalErrorBoundary}
              />
              <OnChangePlugin data={getInitialEditorState()} onChange={onChange} />
            </>
          </TableContext>
        </LexicalComposer>
      </StylesWrapper>
    </SelectionProvider>
  );
};

/**
 * Site theme fonts are loaded by default. Admin theme fonts are loaded if the user is an admin.
 * client/src/components/ui/FontLoader/index.ts
 * We need to ignore the fonts that are already loaded, so there is no flickering when re-render.
 */
const useFilteredFonts = () => {
  const fonts = useFontsContext();
  const isAdmin = useSelector(isUserAdmin);
  const adminFonts = useSelector(getAdminThemeTypography);
  const siteFonts = useSelector(getThemeTypography);

  return React.useMemo(() => {
    const fontsToIgnore = [siteFonts.body, siteFonts.headline];
    if (isAdmin) {
      fontsToIgnore.push(adminFonts.body, adminFonts.headline);
    }

    return fonts.filter((font) => !fontsToIgnore.includes(font.value));
  }, [fonts, adminFonts, siteFonts, isAdmin]);
};

export default React.memo(RichTextEditorV3);
